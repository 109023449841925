
// icons
import { notifications, pin, star } from 'ionicons/icons';

// components
import { IonItem, IonLabel, IonChip, IonBadge, IonIcon, IonThumbnail, IonAvatar, IonImg, } from '@ionic/vue';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

export default {
  props: [
    "project",
    "extraRouterParams",
  ],
  components: { IonItem, IonLabel, IonChip, IonBadge, IonIcon, IonThumbnail, IonAvatar, IonImg, },
  setup() {
    const { t } = useI18n();
    const { formatDate, getProjectStatusColor, getProjectDisplayProgress, addResizeUrlParams, getLocalizedStr, } = utils();

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      notifications, pin, star,

      // methods
      t, getLocalizedStr, formatDate, getProjectStatusColor, getProjectDisplayProgress, addResizeUrlParams,
    }
  }
}
