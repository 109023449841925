import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "horizontal-scroll" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_Circle = _resolveComponent("Circle")!
  const _component_Marker = _resolveComponent("Marker")!
  const _component_GoogleMap = _resolveComponent("GoogleMap")!
  const _component_project_card = _resolveComponent("project-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, {
            fixed: "",
            style: {"padding":"0"}
          }, {
            default: _withCtx(() => [
              ($setup.showSearchBar)
                ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_searchbar, {
                        id: "keyword-searchbar",
                        modelValue: $setup.searchKeyword,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchKeyword) = $event)),
                        "show-cancel-button": "always",
                        placeholder: $setup.t('search'),
                        onIonCancel: _cache[1] || (_cache[1] = ($event: any) => {$setup.showSearchBar = false; $setup.searchKeyword = ''})
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }))
                : ($setup.scanningQRCode)
                  ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                      key: 1,
                      style: {"--background":"#fff"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_buttons, { slot: "primary" }, {
                          default: _withCtx(() => [
                            _withDirectives(_createVNode(_component_ion_button, {
                              onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.stopScan()))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "icon-only",
                                  icon: $setup.close
                                }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }, 512), [
                              [_vShow, $setup.scanningQRCode]
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t('scanningQRCode')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_ion_toolbar, { key: 2 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t('projects')), 1)
                          ]),
                          _: 1
                        }),
                        _withDirectives(_createVNode(_component_ion_buttons, { slot: "start" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.inMapView = false))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "icon-only",
                                  icon: $setup.arrowBack
                                }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 512), [
                          [_vShow, $setup.inMapView]
                        ]),
                        _withDirectives(_createVNode(_component_ion_buttons, { slot: "primary" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              onClick: _cache[4] || (_cache[4] = ($event: any) => {$setup.showSearchBar = true; $setup.focusKeywordSearchbar()})
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "icon-only",
                                  icon: $setup.search
                                }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_button, {
                              onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.startScanProjectQRCode()))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "icon-only",
                                  icon: $setup.qrCodeOutline
                                }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_button, {
                              onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.getCurrentPosition()))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "icon-only",
                                  icon: $setup.navigate
                                }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 512), [
                          [_vShow, !$setup.inMapView]
                        ])
                      ]),
                      _: 1
                    })),
              _withDirectives(_createElementVNode("div", null, [
                _createVNode(_component_ion_toolbar, { mode: "md" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_segment, {
                      modelValue: $setup.statusFilter,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.statusFilter) = $event)),
                      scrollable: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_segment_button, { value: "all" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString($setup.t('all')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_segment_button, {
                          value: $setup.PROJECT_STATUSES.inProgress
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString($setup.t('projectStatus.inProgress')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"]),
                        _createVNode(_component_ion_segment_button, {
                          value: $setup.PROJECT_STATUSES.completed
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString($setup.t('projectStatus.completed')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _withDirectives(_createVNode(_component_ion_toolbar, { mode: "md" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_ion_chip, {
                        onClick: _cache[8] || (_cache[8] = ($event: any) => ($setup.setSelectedProjectType('all'))),
                        outline: $setup.selectedProjectType != 'all'
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.t('all')) + " (" + _toDisplayString($setup.allProjects.length) + ")", 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["outline"]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allProjectTypes, (type) => {
                        return (_openBlock(), _createBlock(_component_ion_chip, {
                          key: type,
                          onClick: ($event: any) => ($setup.setSelectedProjectType(type.name)),
                          outline: $setup.selectedProjectType != type.name
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(type.name) + " (" + _toDisplayString(type.numOfProjects) + ")", 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick", "outline"]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, !$setup.scanningQRCode]
                ])
              ], 512), [
                [_vShow, !$setup.scanningQRCode]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _withDirectives(_createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              ($setup.loading)
                ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (index) => {
                        return _createVNode(_component_ion_item, { key: index }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_skeleton_text)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"80%"}
                                  })
                                ]),
                                _createElementVNode("p", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"60%"}
                                  })
                                ]),
                                _createElementVNode("p", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"30%"}
                                  })
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1024)
                      }), 64))
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _withDirectives(_createVNode(_component_GoogleMap, {
                      "api-key": $setup.googleMapsApiKey,
                      style: {"width":"100%","height":"500px"},
                      center: { lat: $setup.currLat, lng: $setup.currLng },
                      zoom: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Circle, {
                          options: {
                center: { lat: $setup.currLat, lng: $setup.currLng },
                radius: 150,
                strokeColor: '#ffffff',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#5384ED',
                fillOpacity: 0.8,
              }
                        }, null, 8, ["options"]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredProjects(), (p) => {
                          return (_openBlock(), _createBlock(_component_Marker, {
                            key: p.id,
                            options: {
              title: p.title,
              position: { lat: parseFloat(p.latitude), lng: parseFloat(p.longitude) },
              clickable: true,
            },
                            onClick: ($event: any) => ($setup.onClickMapMarker(p))
                          }, null, 8, ["options", "onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["api-key", "center"]), [
                      [_vShow, $setup.inMapView]
                    ]),
                    _withDirectives(_createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredProjects(), (project) => {
                          return (_openBlock(), _createBlock(_component_ion_col, {
                            class: "valign ion-no-padding",
                            size: "6",
                            "size-lg": "4",
                            "size-xl": "3",
                            key: project.id
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_project_card, {
                                project: project,
                                hideDetails: true
                              }, null, 8, ["project"])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    }, 512), [
                      [_vShow, !$setup.inMapView]
                    ])
                  ]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512), [
        [_vShow, !$setup.scanningQRCode]
      ])
    ]),
    _: 1
  }))
}