import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "ion-text-nowrap" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    "router-link": {
      name: 'ProjectDetailPage',
      params: { id: $props.project.id, ...$props.extraRouterParams }
    },
    button: "",
    detail: ""
  }, {
    default: _withCtx(() => [
      ($props.project.imageLink)
        ? (_openBlock(), _createBlock(_component_ion_thumbnail, {
            key: 0,
            slot: "start"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_img, {
                src: $setup.addResizeUrlParams($props.project.imageLink, 300)
              }, null, 8, ["src"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
        default: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString($props.project.title), 1),
          _createElementVNode("p", _hoisted_1, _toDisplayString($props.project.address), 1),
          _createVNode(_component_ion_badge, {
            color: $setup.getProjectStatusColor($props.project.status)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.t($props.project.status)) + _toDisplayString($setup.getProjectDisplayProgress($props.project.progress)), 1)
            ]),
            _: 1
          }, 8, ["color"]),
          _createTextVNode("  "),
          ($props.project.relatedToUser)
            ? (_openBlock(), _createBlock(_component_ion_badge, {
                key: 0,
                color: "tertiary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.star }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString($setup.t('myLocation')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createTextVNode("  "),
          ($props.project.subscribedByUser)
            ? (_openBlock(), _createBlock(_component_ion_badge, {
                key: 1,
                color: "medium"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.t('subscribedNotifications')) + " ", 1),
                  _createVNode(_component_ion_icon, { icon: $setup.notifications }, null, 8, ["icon"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      ($props.project.userRoles.length > 0)
        ? (_openBlock(), _createBlock(_component_ion_chip, {
            key: 1,
            style: {"background":"var(--ion-color-light)"},
            outline: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_avatar, null, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: require('@/assets/avatar.svg')
                  }, null, 8, _hoisted_2)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.project.userRoles.map(r => $setup.getLocalizedStr(r, 'title', 'titleEn')).join(" / ")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["router-link"]))
}